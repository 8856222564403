import React, { useRef } from "react";
import Modal from "react-modal";
import ContactForm from "../components/ContactForm";
import useSiteMetadata from "./SiteMetadata";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    maxHeight: "82vh",
    width: "95%",
    maxWidth: "700px",
  },
};

Modal.setAppElement(`body`);

function ContactFormModal({ modalOpen, onModalClose }) {
  const targetRef = useRef(null);

  function closeModal() {
    onModalClose(false);
  }

  const { services } = useSiteMetadata();

  return (
    <div ref={targetRef}>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal bg-white"
        overlayClassName="Overlay"
      >
        <div className="px-8 pt-8 flex items-top justify-between ">
          <div className="pr-2">
            <h2 className="font-extrabold leading-8 text-2xl md:text-3xl md:mb-2">
              Connect With Us!
            </h2>
            <p className="text-gray-700 hidden md:block">
              <p>Ask us about:</p>
              <ul className="mt-0">
                <li>
                  Our process for making you an{" "}
                  <strong>all-cash offer for your house or land</strong>
                </li>
                <li>
                  Our process for helping property owners stop foreclosure in
                  Sacramento if your situation qualifies
                </li>
                <li>Our company and who we are</li>
                <li>
                  … or anything else you want to ask us — even just to make sure
                  we’re real people 🙂
                </li>
              </ul>
            </p>
          </div>
          <div>
            <button
              onClick={closeModal}
              className="bg-white focus:outline-none focus:bg-gray-50 hover:bg-gray-50 transition duration-150 ease-in rounded-full p-2"
            >
              <svg
                className="text-gray-900 w-8 h-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="px-8 pt-4 pb-8">
          <ContactForm
            showPlaceholders={true}
            hideLabels={true}
            footerContained={false}
            stacked={true}
          />
        </div>
      </Modal>
    </div>
  );
}

export default ContactFormModal;
